//###[ URLS ]###########################################################################################################

$STATIC_URL: "../";
$FONT_PATH: "../fnt/";
$IMG_PATH: "../img/";

//###[ FONTS ]##########################################################################################################

$FONTSIZE_BASE_XS: 14px;
$FONTSIZE_BASE_SM_UP: 18px;
$LINEHEIGHT-BASE: 1.2;

$HEAD_HEADLINE_SIZE: 5rem;

$HEADLINE_SIZE: 3.5rem;

$PRIMARY_HEADLINE_SIZE: 50px;
$PRIMARY_HEADLINE_HEIGHT: 60px;

$SECONDARY_HEADLINE_SIZE: 30px;
$SECONDARY_HEADLINE_HEIGHT: 35px;

$TERTIARY_HEADLINE_SIZE: 24px;
$TERTIARY_HEADLINE_SPACING: 0.1em;
$TERTIARY_HEADLINE_HEIGHT: 30px;

$COPYTEXT_SIZE_MD_UP: 18px;
$COPYTEXT_SIZE_SM: 17px;
$COPYTEXT_SIZE_XS: 15px;
$COPYTEX_SPACING: 1.2px;

$FOOTER_FONTSIZE: 16px; //0.88889rem
$FOOTER_LINEHEIGHT: 36px;


//###[ COLORS ]#########################################################################################################

$MAIN_COLOR: #5c5851;
$MAIN_LIGHT_COLOR: #eee8e4;
// Vars generated by gulp
// $SECONDARY_COLOR: #941017;
// $SECONDARY_LIGHT_COLOR: #d10019;

$BODY_BG_COLOR: white;
$BG_COLOR: $BODY_BG_COLOR;
$HEADER_BG_COLOR: $MAIN_LIGHT_COLOR;
$BOX_BG_COLOR: $BODY_BG_COLOR;

$GREY_DISABLED_OPTION: #bbbbbb;

$BOX_BG_COLOR_LIGHT: #eeeeed;

$MAIN_BORDER_COLOR: $MAIN_LIGHT_COLOR;
$MAIN_BORDER_COLOR_DARK: darken($MAIN_BORDER_COLOR, 10);
$MAIN_COLOR_HOVER: #d2c9c3;
$FOOTER_BORDER_COLOR: #d2d2d2;
$SECONDARY_BORDER_COLOR: $SECONDARY_COLOR;

$ERROR_COLOR: $SECONDARY_LIGHT_COLOR;
$INPUT_BG_COLOR: white;
$INPUT_TEXT_COLOR: $MAIN_COLOR;
$INPUT_BORDER_COLOR: #c2bdb8;
$FIELDSET_HIGHLIGHT_COLOR: $SECONDARY_LIGHT_COLOR;

$CHECKMARK_GREEN: #009640;

$AMAZON_ORANGE: #F79C34;
$PAYPAL_DARK_BLUE: #253B80;
$PAYMENT_LABEL_COLOR: #afafaf;

//###[ BUTTONS ]########################################################################################################

$BUTTON_TEXT_SIZE_SM_UP: 18px;
$BUTTON_TEXT_SIZE_XS: 16px;
$BUTTON_LINE_HEIGHT_SM_UP: 40px;
$BUTTON_LINE_HEIGHT_XS: 42px;
$BUTTON_BG_COLOR: $SECONDARY_LIGHT_COLOR;
$BUTTON_HOVER_BG_COLOR: $SECONDARY_COLOR;
$BUTTON_TEXT_COLOR: white;
$BUTTON_TEXT_COLOR_INVERSED: black;


//###[ ICONS ]########################################################################################################

$icon-sorting_asc-path1: "\e900";
$icon-sorting_asc-path2: "\e901";
$icon-sorting_desc-path1: "\e902";
$icon-sorting_desc-path2: "\e903";
$icon-arrow_down_mini: "\e904";
$icon-arrow_down: "\e905";
$icon-arrow_left: "\e906";
$icon-arrow_right_mini: "\e907";
$icon-arrow_right: "\e908";
$icon-arrow_up: "\e909";
$icon-check: "\e90a";
$icon-geo_pin: "\e90b";
$icon-lupe_desktop: "\e90c";
$icon-lupe_retina: "\e90d";
$icon-pdf_download: "\e90e";
$icon-plus_icon: "\e915";
$icon-wkicon_retina: "\e916";
$icon-x_icon: "\e917";

//###[  ]########################################################################################################
$COLUMN_GAP: 30px;
