$SECONDARY_COLOR: #941017;
$SECONDARY_LIGHT_COLOR: #d10019;
$BRAND_PRIMARY: #d10019;
$BRAND_PRIMARY_DARKEN: #941017;
$MINICART_TOP_POS_MD: 93px;@import 'bootstrap/bootstrap.mod';

@import 'base/defines';

@import 'lib/slick';

@import 'mixins/utils';
@import 'mixins/bootstrap-breakpoints';
@import "mixins/text-image-replace";

@import 'base/fonts';
@import 'base/form';

@import 'layout/header';
@import 'layout/header-search';
@import 'layout/footer';

@import 'module/module-search';
